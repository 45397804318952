






















































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class AxessStockUploader extends Vue {
	isUploading: Boolean = false
	results = []
	headers = [
		{ text: 'Sup.art.no.', align: 'left', value: 'supplier_product_id' },
		{ text: 'Product', value: 'product_description' },
		{ text: 'Quantity', value: 'quantity' },
		{ text: 'Action', value: 'action' },
		{ text: 'Extra info', value: 'reason' }
	]

	upload () {
		this.results = []
		this.isUploading = true
		const data = new FormData()
		data.append('file', (<HTMLInputElement> this.$refs.file).files[0])
		this.$axios.post('/v3/integrations/axess', data)
			.then(response => {
				this.isUploading = false
				this.results = response.data.data || []
				vxm.alert.success({
					content: this.$t('Your file was uploaded successfully') as string,
					visible: true
				})
			})
			.catch(err => {
				this.isUploading = false
			})
	}
}
